<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">A group created the plot below from their data:</p>

      <p class="mb-4">
        <v-img style="max-width: 500px" src="/img/assignments/uciXYGraph.png" />
      </p>

      <p class="mb-3">
        If <stemble-latex content="$\text{y =}$" /> <number-value :value="yVal" />, what is the
        value of <stemble-latex content="$\text{x?}$" /> Pay attention to significant figures.
      </p>

      <calculation-input
        v-model="inputs.input1"
        prepend-text="$\text{x:}$"
        :disabled="!allowEditing"
        class="mb-5"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'ChemUCI1LCQ2S2Q3',
  components: {
    CalculationInput,
    NumberValue,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
    };
  },
  computed: {
    yVal() {
      return this.taskState.getValueBySymbol('yVal').content;
    },
  },
};
</script>
